import React, { Fragment, useState, useEffect } from "react";
import Link from "next/link";
import { Transition, Dialog, Menu } from "@headlessui/react";
import { useRouter } from "next/router";
import axios from "axios";
import
{
    serverAPI,
    actionS3Objects,
    webDomain,
    buttonName,
    environment,
    publicS3Bucket,
} from "../utils/envConfig";
import { deleteCookie, getCookie, hasCookie } from "cookies-next";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import MobileNav from "./MobileNav";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { useRef } from "react";
import Image from "next/image";

function classNames ( ...classes )
{
    return classes.filter( Boolean ).join( " " );
}
const HomeNav = ( { showNewListingForm } ) =>
{
    const loginRef = useRef();
    const loginRefCont = useRef();
    const router = useRouter();
    const [ sidebarOpen, setSidebarOpen ] = useState( false );
    const [ loggedIn, setLoggedIn ] = useState( false );
    const [ avatar, setAvatar ] = useState( null );
    const [ name, setName ] = useState( "" );
    const headers = {
        "Content-Type": "application/json",
    };

    const handleLogout = ( e ) =>
    {
        e.stopPropagation();

        // const local = environment === 'local';

        // local
        deleteCookie( "authuser" );
        // : deleteCookie( "authuser", { path: "/", domain: `${ webDomain }` } );

        localStorage.clear();
        router.reload();
    };

    const clickOutsideLoginRef = ( e ) =>
    {
        if (
            loginRefCont.current &&
            !loginRefCont.current.contains( e.target ) &&
            loginRef.current
        )
        {
            loginRef.current?.classList?.add( "h-0" );
        }
    };

    useEffect( () =>
    {
        if ( hasCookie( "authuser" ) )
        {
            setLoggedIn( true );
            const { first_name, last_name, photo } = JSON.parse(
                getCookie( "authuser" )
            );
            setName( first_name + " " + last_name );

            async function getImage ( file )
            {
                const resp = `https://${ publicS3Bucket }/${ file }`;
                const s3url = resp;
                if ( s3url )
                {
                    setAvatar( s3url );
                }
            }
            if ( photo )
            {
                getImage( photo );
            } else
            {
                setAvatar( "/img/male-avatar.jpg" );
            }
        }
        document.addEventListener( "mousedown", clickOutsideLoginRef );

        return () =>
        {
            document.removeEventListener( "mousedown", clickOutsideLoginRef );
        };
    }, [] );


    return (
        <>


            <div
                className={ `topnav-home absolute z-40 hidden md:block 2xl:top-4 xl:top-4 lg:top-4 md:top-2 top-4 py-3 
                xl:w-[85%] left-1/2 right-1/2 -translate-x-1/2 w-full md:max-w-[750px] container lg:max-w-[930px] mx-auto xl:max-w-[1200px] ${ loggedIn
                        ? "2xl:w-[85%] 3xl:w-[70%]"
                        : "2xl:w-[70%] 3xl:w-[65%]"
                    }` }
            >
                <div className="flex flex-row  justify-between items-center 2xl:text-xl xl:text-xl lg:text-lg md:text-sm text-sm">
                    <div className="flex whitespace-nowrap justify-between items-center text-center lg:gap-x-5 xl:gap-x-10 md:gap-x-4">
                        <img
                            src="/img/horizontal-gold-logo.png"
                            alt="Logo"
                            width={ 170 }
                            height={ 58 }

                        />

                     
                        <Link
                            passHref
                            href={ {
                                pathname: '/find-the-properties',
                                query: {
                                    filter: Buffer.from( JSON.stringify( {
                                        listingType: { name: 'listingType', label: 'Buy', value: 'Buy', title: 'Purpose' }
                                    } ) ).toString( 'base64' )
                                }
                            } }>
                            <a className="block md:inline-block text-white hover:text-[#DFC090] font-semibold cursor-pointer drop-shadow-2xl">
                                Buy                            </a>
                        </Link>
                        <Link passHref
                            href={ {
                                pathname: '/find-the-properties',
                                query: {
                                    filter: Buffer.from( JSON.stringify( {
                                        listingType: { name: 'listingType', label: 'Rent', value: 'Rent', title: 'Purpose' }
                                    } ) ).toString( 'base64' )
                                }
                            } }>
                            <a className="block md:inline-block text-white hover:text-[#DFC090] font-semibold cursor-pointer drop-shadow-2xl">
                                Rent
                            </a>
                        </Link>
                        <Link href="/off-plan-dubai">
                            <a className="block md:inline-block text-white hover:text-[#DFC090] font-semibold cursor-pointer drop-shadow-2xl">
                                Off Plan
                            </a>
                        </Link>

                    </div>
                    <div className="flex  justify-between md:gap-2 lg:gap-3 items-center whitespace-nowrap">
                        <div className="flex lg:gap-x-4 xl:gap-x-10 md:gap-x-4">

                            <Link href="/contact-us">
                                <a className="block md:inline-block text-white hover:text-[#DFC090] font-semibold cursor-pointer drop-shadow-2xl">
                                    Contact
                                </a>
                            </Link>

                            <Link href="/about-us">
                                <a className="block md:inline-block text-white hover:text-[#DFC090] font-semibold cursor-pointer drop-shadow-2xl  md:mr-1 lg:mr-6">
                                    About Us
                                </a>
                            </Link>

                        </div>
                        <button
                            id={ buttonName.newListing }
                            onClick={ showNewListingForm }
                            className="flex flex-row bg-[#DFC090] dark:bg-night hover:bg-night text-base
                             hover:text-[#DFC090] justify-center items-center gap-x-2 text-navy font-Lexend font-semibold cursor-pointer  rounded-lg px-4 py-2"
                        >
                            List Your Property
                        </button>
                        { loggedIn ? (
                            <>
                                {/* Profile dropdown */ }
                                <Menu as="div" className="relative ">
                                    <div>
                                        <Menu.Button className="px-2 flex max-w-xs py-2 2xl:py-0 items-center rounded-full bg-navy dark:bg-night shadow-md text-sm focus:outline-none  ">
                                            <span className="sr-only">
                                                Open user menu
                                            </span>
                                            <div className="flex items-center">
                                                <div>
                                                    <img
                                                        className="inline-block h-8 w-8 rounded-full shadow-md"
                                                        src={ avatar }
                                                        alt="profile"
                                                    />
                                                </div>
                                                <div className="p-2 2xl:flex flex-row items-center justify-center hidden">
                                                    <p className="text-lg font-medium text-[#FFDEAB] px-2">
                                                        { name }
                                                    </p>
                                                    <ChevronDownIcon className="h-4 stroke-[#FFDEAB]" />
                                                </div>
                                            </div>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={ Fragment }
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 lg:w-48 w-42 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
                                            <Menu.Item key={ "profile" }>
                                                <Link href="/profile" passHref>
                                                    <button
                                                        className={ classNames(
                                                            "block w-full px-4 py-2 text-sm text-start text-gray-700 hover:text-[#A6873B] font-lexend hover:font-bold"
                                                        ) }
                                                    >
                                                        Profile
                                                    </button>
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key={ "favourites" }>
                                                <Link
                                                    href="/favourites"
                                                    passHref
                                                >
                                                    <button
                                                        className={ classNames(
                                                            "block w-full px-4 py-2 text-sm text-start text-gray-700 hover:text-[#A6873B] font-lexend hover:font-bold "
                                                        ) }
                                                    >
                                                        Favourites
                                                    </button>
                                                </Link>
                                            </Menu.Item>

                                            <Menu.Item key={ "logout" }>
                                                <button
                                                    onClick={ ( e ) =>
                                                        handleLogout( e )
                                                    }
                                                    className={ classNames(
                                                        "block w-full px-4 py-2 text-sm text-start text-gray-700 hover:text-[#A6873B] font-lexend hover:font-bold"
                                                    ) }
                                                >
                                                    Logout
                                                </button>
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </>
                        ) : (
                            <div
                                className="relative my-auto"
                                ref={ loginRefCont }
                            >
                                <button
                                    className="flex login-button flex-row dark:bg-night bg-night text-white justify-center 
                                        items-center   font-Lexend font-semibold text-base cursor-pointer hover:bg-[#998464] rounded-lg px-3 xl:py-2 py-3"
                                    onClick={ () =>
                                    {
                                        if ( loginRef.current )
                                        {
                                            if (
                                                loginRef.current?.classList?.contains(
                                                    "h-0"
                                                )
                                            )
                                            {
                                                loginRef.current?.classList?.remove(
                                                    "h-0"
                                                );
                                            } else
                                            {
                                                loginRef.current?.classList?.add(
                                                    "h-0"
                                                );
                                            }
                                        }
                                    } }
                                >
                                    <img
                                        src="/img/user_account.svg"
                                        alt="User Account"
                                        width={ 16 }
                                        height={ 16 }
                                        className="xl:mr-1"
                                        loading="lazy"
                                    />
                                    <span className="hidden xl:flex">
                                        Account
                                    </span>
                                </button>
                                <div
                                    className="absolute top-[120%] left-0 rounded-lg bg-white flex flex-col 
                                    transition-all duration-100 ease-linear overflow-hidden h-0"
                                    ref={ loginRef }
                                >
                                    <Link href={ "/register" }>
                                        <a className="px-7 text-navy font-bold font-Lexend pt-4   hover:text-[#A6873B]">
                                            Register
                                        </a>
                                    </Link>
                                    <Link href={ "/login" }>
                                        <a className="px-7 text-navy font-bold font-Lexend pt-4  pb-4  hover:text-[#A6873B]">
                                            Login
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        ) }
                    </div>
                </div>
            </div>
            <MobileNav showNewListingForm={ showNewListingForm } />
        </>
    );
};

export default HomeNav;
