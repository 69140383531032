import React, { Fragment, useState, useEffect } from "react";
import Link from "next/link";
import { Transition, Dialog, Menu } from "@headlessui/react";
import { XIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import { useRouter } from "next/router";
import { deleteCookie, hasCookie } from "cookies-next";
import { webDomain } from "../utils/envConfig";
import Image from "next/image";

function MobileNav ( { showNewListingForm = () => null } )
{
  const router = useRouter();
  const [ sidebarOpen, setSidebarOpen ] = useState( false );
  const [ loggedIn, setLoggedIn ] = useState( false );
  const [ isHome, setIsHome ] = useState( false );
  const handleLogout = ( e ) =>
  {
    e.stopPropagation();

    deleteCookie( "authuser", { path: "/", domain: `${ webDomain }` } );
    localStorage.clear();
    router.reload();
  };
  useEffect( () =>
  {
    if ( hasCookie( "authuser" ) )
    {
      setLoggedIn( true );
    }
    if ( router.pathname == "/" )
    {
      setIsHome( true );
    }
  }, [] );

  return (
    <div className="absolute z-40 md:hidden block w-full bg-night  py-2 px-8 ">
      <div className="flex justify-between">
        {/* { !isHome && (
          <button type="button" onClick={ () => router.back() }>
            <ArrowLeftIcon className="text-white w-8 h-8" />
          </button>
        ) } */}
        <Link href="/" passHref>
          <div className="cursor-pointer">
            <img
              className="object-center"
              src="./img/horizontal-gold-logo.png"
              width="160"
            />
          </div>
        </Link>
        <button type="button" onClick={ () => setSidebarOpen( ( t ) => !t ) }>
          <i className="toggle block fa-solid fa-bars text-3xl text-[#FFDEAB] "></i>
        </button>
      </div>
      <Transition.Root show={ sidebarOpen } as={ Fragment }>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden overflow-y-auto w-screen overflow-x-hidden"
          onClose={ setSidebarOpen }
        >
          <Transition.Child
            as={ Fragment }
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-white" />
          </Transition.Child>
          <Transition.Child
            as={ Fragment }
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col  w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={ Fragment }
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute z-[100] top-11 left-full w-full">
                  <div
                    className="select-none ml-1 flex items-center bg-gray-100 justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-main"
                    onClick={ () => setSidebarOpen( false ) }
                  >
                    <span className="sr-only select-none">Close sidebar</span>
                    <XIcon
                      className="h-6 w-6 text-night font-Lexend select-none "
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </Transition.Child>
              <div className="relative w-full p-6">
                <div className="relative py-4">
                  <div
                    className="absolute inset-0 flex items-center px-6"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-start ">
                    <span className="pr-2 bg-white text-xl text-[#A6873B] font-Lexend font-bold">
                      Menu
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col space-y-2 text-night text-3xl">
                <div className="relative w-full px-6 select-none cursor-pointer">
                  <Link href="/" passHref>
                    <a className="bg-[#DFC090] text-night font-Lexend flex flex-row gap-4 justify-start items-center w-full px-2 py-4 font-medium rounded-md  hover:bg-night hover:text-[#FFDEAB]">
                      Home
                    </a>
                  </Link>
                </div>
                <div className="relative w-full px-6 select-none cursor-pointer">
                  <Link passHref
                    href={ {
                      pathname: '/find-the-properties',
                      query: {
                        filter: Buffer.from( JSON.stringify( {
                          listingType: { name: 'listingType', label: 'Buy', value: 'Buy', title: 'Purpose' }
                        } ) ).toString( 'base64' )
                      }
                    } }>
                    <a className="flex flex-row gap-4 justify-start items-center w-full px-2 py-4 font-medium rounded-md  hover:bg-night hover:text-[#FFDEAB]">
                      Buy
                    </a>
                  </Link>
                </div>
                <div className="relative w-full px-6 select-none cursor-pointer">
                  <Link passHref
                    href={ {
                      pathname: '/find-the-properties',
                      query: {
                        filter: Buffer.from( JSON.stringify( {
                          listingType: { name: 'listingType', label: 'Rent', value: 'Rent', title: 'Purpose' }
                        } ) ).toString( 'base64' )
                      }
                    } }>
                    <a className="flex flex-row gap-4 justify-start items-center w-full px-2 py-4 font-medium rounded-md  hover:bg-night hover:text-[#FFDEAB]">
                      Rent
                    </a>
                  </Link>
                </div>
                <div className="relative w-full px-6 select-none cursor-pointer">
                  <Link href="/off-plan-dubai" passHref>
                    <a className="flex flex-row gap-4 justify-start items-center w-full px-2 py-4 font-medium rounded-md  hover:bg-night hover:text-[#FFDEAB]">
                      Off Plan
                    </a>
                  </Link>
                </div>
                <div className="relative w-full px-6 select-none cursor-pointer">
                  <Link href="/about-us" passHref>
                    <a className="flex flex-row gap-4 justify-start items-center w-full px-2 py-4 font-medium rounded-md  hover:bg-night hover:text-[#FFDEAB]">
                      About Us
                    </a>
                  </Link>
                </div>
                <div className="relative w-full px-6 select-none cursor-pointer">
                  <Link href="/contact-us" passHref>
                    <a className="flex flex-row gap-4 justify-start items-center w-full px-2 py-4 font-medium rounded-md  hover:bg-night hover:text-[#FFDEAB]">
                      Contact Us
                    </a>
                  </Link>
                </div>
                { !loggedIn && (
                  <>
                    <div
                      className="relative w-full px-6 select-none cursor-pointer"
                      onClick={ showNewListingForm }
                    >
                      <div className="flex flex-row gap-4 justify-start items-center w-full px-2 py-4 font-medium rounded-md  hover:bg-night hover:text-[#FFDEAB]">
                        New Listing
                      </div>
                    </div>
                    <div className="relative w-full px-6 select-none cursor-pointer">
                      <Link href="/login" passHref>
                        <a className="flex flex-row gap-4 justify-start items-center w-full px-2 py-4 font-medium rounded-md  hover:bg-night hover:text-[#FFDEAB]">
                          Login
                        </a>
                      </Link>
                    </div>

                  </>
                ) }
              </div>
              { loggedIn && (
                <>
                  <div className="relative py-4">
                    <div
                      className="absolute inset-0 flex items-center "
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-start ">
                      <span className="pr-2 bg-white text-xl text-[#a6873b] font-bold px-6">
                        Account
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2 text-navy font-semibold text-3xl">
                    <div className="relative w-full px-6 select-none cursor-pointer">
                      <Link href="/profile" passHref>
                        <a className="flex flex-row gap-4 justify-start items-center w-full px-2 py-4 font-medium rounded-md  hover:bg-night hover:text-[#FFDEAB]">
                          Profile
                        </a>
                      </Link>
                      <Link href="/favourites" passHref>
                        <a className="flex flex-row gap-4 justify-start items-center w-full px-2 py-4 font-medium rounded-md  hover:bg-night hover:text-[#FFDEAB]">
                          Favourites
                        </a>
                      </Link>
                    </div>

                    <div className="relative w-full px-6 select-none cursor-pointer">
                      <button
                        onClick={ ( e ) => handleLogout( e ) }
                        className="flex flex-row gap-4 justify-start items-center w-full px-2 py-4 font-medium rounded-md  hover:bg-night hover:text-[#FFDEAB]"
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                </>
              ) }
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
export default MobileNav;
