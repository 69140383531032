import React, { useState, useEffect } from "react";
import HomeNav from "./HomeNav";

import SearchBox from "./NewUIComponents/SearchBox";

const HomeBanner = ( { showNewListingForm } ) =>
{


  //  Night mode
  const [ change, setChange ] = useState( false );
  useEffect( () =>
  {

    const hour = new Date().getHours();

    // Set timer to switch day and night mode
    const timer = setInterval( () =>
    {
      // We want to show night mode from 6pm t0 6am
      setChange( hour >= 18 || hour < 6 );
    }, 60 * 1000 * 60 );

    setChange( hour >= 18 || hour < 6 );

    return () =>
    {
      clearInterval( timer );
    };
  }, [] );
  return (
    <div
      className={ `lg:h-[37rem] xl:h-[49rem] 2xl:h-[55rem] 3xl:h-[62rem] 4xl:h-[62rem] 6xl:h-[70rem] md:h-[35rem] 
    4xs:h-[52rem] 3xs:h-[51rem] 2.5xs:h-[52rem] 2xs:h-[56rem] xs:h-[61rem]  sm:h-[68rem]  relative  ${ change ? "bg-main-banne-night" : "bg-main-banner"
        }` }
    >
      <HomeNav showNewListingForm={ showNewListingForm } />
      {/* Floating city animation  */ }
      <div
        className="absolute hidden md:block z-10 pointer-events-none md:top-0 city-animation mt-28 md:mt-[2rem] sm:mt-20  
      lg:mt-0 4xl:scale-90 4xl:mt-0"
      >
        <img alt='banner-image' src="/lotties/images/img_0.webp" />
      </div>
      {/* Floating city tail */ }

      <div className="absolute md:hidden 4xs:block z-10 pointer-events-none  top-24 ml-2 ">
        <img
          alt="banner-image"
          width={ 750 }
          height={ 420 }
          src="/lotties/images/img_0.webp"
        />
      </div>

      {/* For Mobile : static image */ }
      <div className="absolute 4xs:hidden block z-10 ml-2 ">
        <img
          alt="banner-image-mobile"
          width={ 500 }
          height={ 281 }
          src="/lotties/images/img_mobile.webp"
        />
      </div>
      { !change && (
        <>
        
          {/* Backgroud static image day*/ }
          <picture>
            <source
              srcSet="/img/gradientupdated.webp"
              type="image/webp"
            />
            <img
              alt="gradient-clouds"
              src="/img/gradientupdated.webp"
              id="bgrid"
              className="object-cover object-bottom"
            />
          </picture>

        </>
      ) }
      {/* Backgroud static image night*/ }
      { change && (
        <picture>
          <source
            srcSet="/img/night-sky.webp"
            type="image/webp"
          />
          <img
            alt="gradient-clouds"
            src="/img/night-sky.webp"
            id="bgrid"
            className="object-cover object-bottom"
          />
        </picture>


      ) }
      <div className="absolute z-10 2xl:top-[265px] lg:top-36 xl:top-44 md:top-36 sm:top-32  top-[8rem] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full">
        <h1 className="hidden md:block text-white xl:text-5xl lg:text-3xl md:text-2xl text-[20px] font-light font-Mont leading-10 tracking-wider text-center">
          Discover Dubai&nbsp;&nbsp;&nbsp;&nbsp; Discover Home{ " " }
        </h1>
        <h1 className="md:hidden text-center  text-white sm:text-[26px] 4xs:text-[1.2rem] xs:text-[1.6rem]  font-bold font-Mont leading-10 ">
          Discover Dubai&nbsp;&nbsp;&nbsp;  Discover Home
        </h1>
      </div>
      <SearchBox></SearchBox>
    </div>
  );
};

export default HomeBanner;
